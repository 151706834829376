<template>
  <div class="content">
    <base-header class="pb-7">
      <div class="row align-items-center py-4 d-md-none">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">统计表</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div>
        <card refs="l1" class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="d-flex justify-content-center justify-content-sm-between flex-wrap filters">
                <div class="mb-1">
                  <el-select v-model="filters.data_type" placeholder="统计表类型" :clearable="true">
                    <el-option label="采购统计表" value="0"></el-option>
                    <el-option label="销售统计表" value="1"></el-option>
                  </el-select>
                </div>
                <div class="ml-2">
                  <el-button type="success" @click="fetchData" :loading="loading">{{ this.loading ? "加载中..." : "检索" }}</el-button>
                </div>
              </div>
              <div class="mb-3 mb-md-1">
                 <el-dropdown type="success" @command="addData">
                    <el-button type="success" style="margin-left: 5px; margin-bottom: 5px;" :disabled="editing"><i class="el-icon-plus"></i> 新增 <i class="el-icon-arrow-down el-icon--right"></i></el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="0">广州国有企业采购帮扶产品数据统计表</el-dropdown-item>
                        <el-dropdown-item :command="1">广州国有企业销售帮扶产品数据统计表</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-table refs="storeTable" row-key="id" header-row-class-name="thead-light" style="width: 100%" v-loading="loading"
                :data="tableData" stripe>
                <el-table-column prop="data_type" label="统计表类型" min-width="80" class-name="pl-2">
                    <template slot-scope="{row}">
                        <span v-if="row.data_type === 0">采购</span>
                        <span v-else-if="row.data_type === 1">销售</span>
                        <span v-else></span>
                    </template>
                </el-table-column>
                <el-table-column prop="year" label="年份" min-width="80"></el-table-column>
                <el-table-column prop="start_date" label="起始日期" min-width="100">
                    <template slot-scope="{row}">
                        {{ toDateString(row.start_date) }}
                    </template>
                </el-table-column>
                <el-table-column prop="end_date" label="截止日期" min-width="100">
                    <template slot-scope="{row}">
                        {{ toDateString(row.end_date) }}
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态" min-width="100">
                    <template slot-scope="{row}">
                        <span v-if="row.status === -1">暂存</span>
                        <span v-else-if="row.status === 0">审核中</span>
                        <span v-else-if="row.status === 1">审核通过</span>
                        <span v-else-if="row.status === 2">审核未通过</span>
                        <span v-else-if="row.status === 3">申请退回中</span>
                        <span v-else></span>
                    </template>
                </el-table-column>
                <el-table-column label="最后填报时间" property="created_at" width="180">
                    <template slot-scope="{row}">
                        {{ toDateTimeString(row.updated_at || row.created_at) }}
                    </template>
                </el-table-column>
                <el-table-column width="130px" label="操作" class-name="px-2 text-center" label-class-name="action-col-header" fixed="right">
                    <template slot-scope="{row}">
                        <a href="javascript:;" class="border-bottom mx-1" @click="open(row)"> 打开</a>
                        <el-popconfirm confirm-button-type="danger" :disabled="isFrozen(row)"
                          title="确定要删除记录？" confirm-button-text="删除" cancel-button-text="取消" @confirm="remove(row)">
                          <a slot="reference" href="javascript:;" class="border-bottom mx-1" :class="isFrozen(row)?'disabled':''"> 删除</a>
                        </el-popconfirm>
                    </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div slot="footer" class="col-12 px-0 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-pagination class="pb-3 mx--3" background layout="sizes, prev, pager, next"
              :page-sizes="pageSizeOptions" :page-size="filters.limit"
              :total="total" :current-page="filters.page"
              @size-change="pageSizeChange" @current-change="pageIndexChange" @prev-click="movePrev" @next-click="moveNext">
            </el-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-drawer :visible="editing" :append-to-body="true" :modal-append-to-body="true" :before-close="beforeCloseImport"
      direction="ltr" size="50%"
      :title="newDataType===0?'导入《广州国有企业采购帮扶产品数据统计表》':'导入《广州国有企业销售帮扶产品数据统计表》'">
        <div class="px-4">
          <import-form v-if="editing" :data-type="newDataType" @saved="successAdd" @cancelled="cancelAdd"></import-form>
        </div>
    </el-drawer>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

export default {
  components: {
    RouteBreadCrumb,
    'import-form': () => import('./Import.vue')
  },
  data() {
    return {
      loading: false,
      editing: false,
      filters: { data_type: '', limit: 10, page: 1 },
      tableData: [],
      pageSizeOptions: [10, 20, 50, 100],
      total: 0,
      newDataType: null
    }
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.axios.get("datainputs", { params: { ...this.filters } })
      .then((res) => {
        if (res.data.errcode == 0) {
            this.tableData = res.data.data.data
            this.total = res.data.data.total
            this.filters.page = res.data.data.page
        }
        else {
            this.$notifyErr('未能成功加载数据', res.data.errmsg);
        }
        this.loading = false;
      })
      .catch((e) => {
        console.error(e);
        this.loading = false;
      })
    },
    pageSizeChange(v) { this.filters.limit = v; this.fetchData() },
    pageIndexChange(v) { this.filters.page = v; this.fetchData() },
    movePrev() { this.filters.page --; this.fetchData() },
    moveNext() { this.filters.page ++; this.fetchData() },
    isFrozen (row) {
      return row.status===0||row.status===1||row.status===3
    },
    addData (cmd) {
      this.$confirm('系统只收集并统计企业填报的消费帮扶数据，数据的真实性、完整性由填报企业负责。', '提示', {
        confirmButtonText: '同意',
        confirmButtonClass: 'el-button--success',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        this.newDataType = cmd
        this.editing = true
      })
    },
    successAdd () {
        this.fetchData()
        this.editing = false
    },
    cancelAdd () {
        this.editing = false
    },
    open (row) {
        this.$router.push('/datainputs/' + row.id)
    },
    beforeCloseImport (done) {
      this.cancelAdd()
      done()
    },
    remove (row) {
      row.deleting = true
      this.axios.delete('datainputs/' + row.id).then((res) => {
        if (res.data.errcode == 0) {
          for (var i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].id === row.id) {
              this.tableData.splice(i, 1)
              break
            }
          }
          this.$notifyOK('操作成功', '删除了一条记录')
        }
        else {
            this.$notifyErr('操作失败', res.data.errmsg)
        }
        row.deleting = false
      }).catch((e) => {
        this.$notifyErr('操作失败', e.message)
        row.deleting = false
      })
    }
  },
  mounted () {
    this.fetchData()
  }
};
</script>
<style>
  .no-border-card .card-footer { border-top: 0; }
  .filters>div { margin-bottom:15px; }
  .filters .search.el-input { width: 230px; }
  .filters .el-select { width: 160px }
  .cell.action-col-header {
      display: block !important;
      text-align: center;
      padding-top: 9px !important;
  }
</style>
